<template>
  <div>
    <div v-if="loading" class="text-center">
      <a-spin tip="Loading..." />
    </div>
    <a-form-model
      v-if="!loading"
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="name" label="会社グループ名" prop="name">
        <a-input
          v-model="form.name"
          @blur="
            () => {
              $refs.name.onFieldBlur()
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item ref="comment" label="コメント" prop="comment">
        <a-input
          v-model="form.comment"
          @blur="
            () => {
              $refs.phonetic.onFieldBlur()
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit">
          {{ submitString }}
        </a-button>
        <router-link :to="{ name: 'companyGroupShow', params: { id: $route.params.id } }">
          <a-button style="margin-left: 10px;">
            戻る
          </a-button>
        </router-link>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>

import Vue from 'vue'

export default {
  name: 'FormCompanyGroup',
  data() {
    return {
      loading: true,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      companyGroup: this.companyGroupProps,
      form: {
        name: '',
        comment: '',
      },
      rules: {
        name: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
      },
    }
  },
  props: [
    'companyGroupProps',
    'submitType',
    'submitString',
  ],
  watch: {
    companyGroup: function(val) {
      this.form.name = val.name
      this.form.comment = val.comment
    },
  },
  methods: {
    onSubmit() {
      const _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (_this.submitType === 'post') {
            const send = Vue.prototype.$api.send('post', 'company_groups', this.form)
            send.then(response => {
              _this.$notification['success']({
                message: this.form.name + ' を作成しました。',
              })
              _this.$router.push({ name: 'companyGroupShow', params: { id: response.id } })
            })
              .catch(error => {
                if (error.status === 403) {
                  _this.$notification['error']({
                    message: error.data.data,
                  })
                } else {
                  for (var key in error.data.errors) {
                    _this.$refs[key].validateState = 'error'
                    _this.$refs[key].validateMessage = error.data.errors[key]
                  }
                }
                console.log(error)
              })
          } else if (_this.submitType === 'put') {
            const send = Vue.prototype.$api.send('put', 'company_groups/' + _this.$route.params.id, this.form)
            send.then(response => {
              _this.$notification['success']({
                message: this.form.name + ' を更新しました。',
              })
              _this.$router.push({ name: 'companyGroupShow', params: { id: _this.$route.params.id } })
            })
              .catch(error => {
                if (error.status === 403 || error.status === 400) {
                  _this.$notification['error']({
                    message: error.data.data,
                  })
                } else {
                  for (var key in error.data.errors) {
                    _this.$refs[key].validateState = 'error'
                    _this.$refs[key].validateMessage = error.data.errors[key]
                  }
                }
                console.log(error)
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
  created() {
    if (this.submitType === 'put') {
      const result = Vue.prototype.$api.send('get', 'company_groups/' + this.$route.params.id)
      result.then(response => {
        this.loading = false
        this.companyGroup = response
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 会社グループの取得に失敗しました。',
          })
          this.loading = false
        })
    } else {
      this.loading = false
    }
  },
}
</script>
