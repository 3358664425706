<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">会社グループ編集</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form-company-group
              :submitType="submitType"
              :submitString="submitString"
            />
            <p class="text-right">
              <a-button type="danger" @click="showDeleteConfirm">削除</a-button>
            </p>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      v-model="visible"
      title="削除の確認"
      okText="削除"
      okType="danger"
      cancelText="キャンセル"
      @ok="handleOk"
    >
      <p>本当にこの会社グループを削除してもよろしいですか？</p>
    </a-modal>
  </div>
</template>

<script>
import FormCompanyGroup from '../../../components/FormComponents/CompanyGroup'
import Vue from 'vue'

export default {
  components: {
    FormCompanyGroup,
  },
  props: {
    submitType: {
      type: String,
      default: 'put',
    },
    submitString: {
      type: String,
      default: '更新',
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  created() {
  },
  methods: {
    showDeleteConfirm() {
      this.visible = true
    },
    handleOk() {
      this.deleteGroup()
    },
    deleteGroup() {
      const _this = this
      const send = Vue.prototype.$api.send('delete', 'company_groups/' + this.$route.params.id)
      send.then(response => {
        _this.$notification['success']({
          message: '会社グループを削除しました。',
        })
        _this.$router.push('/master/company_groups')
      })
        .catch(error => {
          console.log(error)
          _this.$notification['error']({
            message: '会社グループの削除に失敗しました。',
          })
        })
    },
  },
}
</script>

<style>
.list-name {
  font-size: 1.5rem;
}
</style>
