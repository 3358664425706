var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [_c("a-spin", { attrs: { tip: "Loading..." } })],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol
              }
            },
            [
              _c(
                "a-form-model-item",
                {
                  ref: "name",
                  attrs: { label: "会社グループ名", prop: "name" }
                },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.name.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "comment",
                  attrs: { label: "コメント", prop: "comment" }
                },
                [
                  _c("a-input", {
                    on: {
                      blur: function() {
                        _vm.$refs.phonetic.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.comment,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "comment", $$v)
                      },
                      expression: "form.comment"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.submitString) + "\n      "
                      )
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "companyGroupShow",
                          params: { id: _vm.$route.params.id }
                        }
                      }
                    },
                    [
                      _c(
                        "a-button",
                        { staticStyle: { "margin-left": "10px" } },
                        [_vm._v("\n          戻る\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }