var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("form-company-group", {
                  attrs: {
                    submitType: _vm.submitType,
                    submitString: _vm.submitString
                  }
                }),
                _c(
                  "p",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "danger" },
                        on: { click: _vm.showDeleteConfirm }
                      },
                      [_vm._v("削除")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c(
        "a-modal",
        {
          attrs: {
            title: "削除の確認",
            okText: "削除",
            okType: "danger",
            cancelText: "キャンセル"
          },
          on: { ok: _vm.handleOk },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("p", [
            _vm._v("本当にこの会社グループを削除してもよろしいですか？")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "utils__title mb-3" }, [
      _c("strong", { staticClass: "text-uppercase font-size-16" }, [
        _vm._v("会社グループ編集")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }